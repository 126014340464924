import { Button } from "@doar/components";
import { light } from "@doar/shared/styled/colors";
import { Suspense, lazy } from "react";
import { XCircle } from "react-feather";
import { Toaster, toast, ToastBar } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import {
    BrowserRouter as Router,
    Routes,
    Route,
    HashRouter,
} from "react-router-dom";
import Preloader from "./components/preloader";
import SearchProvider from "./hooks/search-context";
import UserProvider from "./hooks/user-context";

import "@doar/shared/data/i18n";

import "driver.js/dist/driver.css";
import LanguageRouter from "./routers/LanguageRouter";

// Classic Pages
const SignUp = lazy(() => import("./pages/signup"));

const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const QuestionSheet = lazy(() => import("./pages/question-sheet"));
const QuestionEditor = lazy(() => import("./pages/question-editor"));
const QuickTest = lazy(() => import("./pages/quick-test"));
const LandingPage = lazy(() => import("./pages/landing"));
const MoreCourses = lazy(() => import("./pages/more-courses"));
const Assignments = lazy(() => import("./pages/assignments"));
const ExamMarking = lazy(() => import("./pages/exam-marking"));
const Signin = lazy(() => import("./pages/signin"));
const MarkdownTest = lazy(() => import("./pages/markdown-test"));

const App = () => {
    const client = new QueryClient();
    return (
        <QueryClientProvider client={client}>
            <Toaster
                position="top-center"
                containerStyle={{
                    top: 30,
                    left: 30,
                    bottom: 30,
                    right: 30,
                }}
                toastOptions={{
                    // Define default options
                    className: "",
                    style: {
                        background: "white",
                        color: "#000",
                        fontSize: "1rem",
                        borderRadius: "0.5em",
                        border: "1px solid",
                        borderColor: light.gray200,
                        padding: "0.5em 0.8em 0.5em 0.8em",
                    },

                    // Default options for specific types
                    success: {
                        duration: 6000,
                        theme: {
                            primary: "green",
                            secondary: "grey",
                        },
                    },
                }}
            >
                {(t) => (
                    <ToastBar toast={t}>
                        {({ icon, message }) => (
                            <>
                                {icon}
                                {message}
                                {t.type !== "loading" && (
                                    <Button
                                        variant="texted"
                                        size="md"
                                        color="light"
                                        iconButton
                                        width={"2em"}
                                        height={"2em"}
                                        onClick={() => toast.dismiss(t.id)}
                                    >
                                        <i className="fa fa-times" />
                                    </Button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
            <SearchProvider>
                <HashRouter>
                    <Suspense fallback={<Preloader />}>
                        <UserProvider>
                            <LanguageRouter client={client}>
                                <Routes>
                                    {/* Classic Routes */}
                                    <Route path="/" element={<Signin />} />
                                    <Route
                                        path="/markdown-test"
                                        element={<MarkdownTest />}
                                    />
                                    <Route
                                        path="/quick-test"
                                        element={<QuickTest />}
                                    />
                                    <Route
                                        path="/questions/:type/:id/mode/:mode"
                                        element={<QuestionSheet />}
                                    />
                                    <Route
                                        path="/questions/:type/:id"
                                        element={<QuestionSheet />}
                                    />

                                    <Route
                                        path="/editor/module/"
                                        element={<QuestionEditor />}
                                    />
                                    <Route
                                        path="/editor/:type/:id"
                                        element={<QuestionEditor />}
                                    />
                                    {/* Dashboard Routes */}

                                    {/* Error Routes */}
                                    <Route
                                        path="/error-500"
                                        element={<Error500 />}
                                    />
                                    <Route
                                        path="/error-503"
                                        element={<Error503 />}
                                    />
                                    <Route
                                        path="/error-505"
                                        element={<Error505 />}
                                    />

                                    {/* User Routes */}
                                    <Route
                                        path="/signin"
                                        element={<Signin />}
                                    />

                                    <Route
                                        path="/home"
                                        element={<Assignments />}
                                    />

                                    <Route
                                        path="/assignments"
                                        element={<Assignments />}
                                    />

                                    <Route
                                        path="/get-more-courses"
                                        element={<MoreCourses />}
                                    />
                                    <Route
                                        path="/exam-results"
                                        element={<ExamMarking />}
                                    />

                                    {/* Dashboard Routes */}
                                    <Route
                                        path="exam-marking/:examId"
                                        element={<ExamMarking />}
                                    >
                                        <Route
                                            path="token/:token"
                                            element={<ExamMarking />}
                                        />
                                    </Route>
                                    {/* 404 Page Route */}
                                    <Route
                                        path="*"
                                        element={<ErrorNotFound />}
                                    />
                                </Routes>
                            </LanguageRouter>
                        </UserProvider>
                    </Suspense>
                </HashRouter>
                <ReactQueryDevtools initialIsOpen={false} />
            </SearchProvider>
        </QueryClientProvider>
    );
};

export default App;
