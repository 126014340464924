import axios from "axios";
import { createContext, useEffect, useState } from "react";

export const CDNBaseURLContext = createContext<string>("");

export const CDNBaseURLProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [domainState, setDomain] = useState("");

    useEffect(() => {
        const runSetDomainState = async () => {
            setDomain(
                (
                    await axios.get<string>(
                        (
                            (process.env
                                .REACT_APP_MARKR_SERVER_URL as string) ?? ""
                        ).concat(`/file/cdn_base_url`)
                    )
                ).data
            );
        };

        runSetDomainState().catch((e) => {
            console.log("Failed to get cdn base url!");
        });
    });

    return (
        <CDNBaseURLContext.Provider value={domainState}>
            {children}
        </CDNBaseURLContext.Provider>
    );
};
