/* eslint-disable @typescript-eslint/no-non-null-assertion */
// import { config } from "dotenv";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import App from "./App";
import { DropdownProvider } from "./components/suggestion-dropdown/dropdownContext";
import { CDNBaseURLProvider } from "./hooks/cdn-base-url-context";

// config();
const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <CDNBaseURLProvider>
        <Provider store={store}>
            <PersistProvider>
                <ThemeProvider>
                    <DropdownProvider>
                        <App />
                    </DropdownProvider>
                </ThemeProvider>
            </PersistProvider>
        </Provider>
    </CDNBaseURLProvider>
);
