import {
    Layers,
    Edit,
    Home,
    HelpCircle,
    Tag,
    Edit2,
    Hash,
} from "react-feather";
import { IMenu } from "@doar/shared/types";

export const basicMenus = <IMenu[]>[
    // {
    //     id: 0,
    //     label: "Home",
    //     url: "/home",
    //     Icon: Home,
    // },
];

export const userMenus = [
    // {
    //     id: 1,
    //     label: "Home",
    //     url: "/home",
    //     Icon: Home,
    // },
];
export const editorMenus = <IMenu[]>[
    {
        id: 3,
        label: "menu.editor",
        url: "/editor/module/",
        Icon: Edit,
        megamenu: [
            {
                id: 31,
                title: "menu.questions",
                Icon: Layers,
                submenu: [
                    {
                        id: 310,
                        label: "menu.question_editor",
                        url: "/editor/module/",
                        Icon: HelpCircle,
                    },
                    {
                        id: 311,
                        label: "menu.markdown_test",
                        url: "/markdown-test",
                        Icon: Hash,
                    },
                    {
                        id: 312,
                        label: "menu.concepts",
                        url: "/concepts",
                        Icon: Tag,
                    },
                ],
            },
            {
                id: 32,
                title: "menu.universities",
                Icon: Home,
                submenu: [
                    {
                        id: 321,
                        label: "menu.institutions",
                        url: "/editor/institutions",
                        Icon: Home,
                    },
                ],
            },
        ],
    },
];

export const teacherMenus = [
    {
        id: 4,
        label: "menu.grading",
        url: "/assignments",
        Icon: Edit2,
    },
];

export const demoMenus = [
    // {
    //     id: 3,
    //     label: "Teacher",
    //     url: "/",
    //     Icon: User,
    //     submenu: [
    //         {
    //             id: 7,
    //             label: "Grading",
    //             url: "/assignments",
    //             Icon: Edit2,
    //         },
    //     ],
    // },
];
